import { render, staticRenderFns } from "./App.vue?vue&type=template&id=71a5cc0e&"
var script = {}
import style0 from "./App.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../usr/lib/node_modules/@vue/cli-service-global/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports